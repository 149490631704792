// portfolioItems.js

import Blog1Pic from '../Assets/Blogs/Blog1.png'

export const BlogData = [
    {
        pic:Blog1Pic,
        title: "Blog ",
        category1: "Development",
        category2: "AI",
        description: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Rerum, iure.",
        author: "Usman Khalil",
        date: "Feb 15, 2024",
      },
      {
        pic:Blog1Pic,

          title: "Blog 2",
          category1: "Mobile ",
          category2: "SMM",
          description: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Rerum, iure.",
          author: "Author Name",
          date: "Date posted",
        },{
        pic:Blog1Pic,

          title: "Blog 1",
          category1: "Development",
          category2: "AI",
          description: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Rerum, iure.",
          author: "Author Name",
          date: "Date posted",
        },{
        pic:Blog1Pic,

          title: "Blog 1",
          category1: "Development",
          category2: "AI",
          description: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Rerum, iure.",
          author: "Author Name",
          date: "Date posted",
        },
        {
        pic:Blog1Pic,

          title: "Blog",
          category1: "Development",
          category2: "0okkk",
          description: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Rerum, iure.",
          author: "Author Name",
          date: "Date hehehe",
        },{
        pic:Blog1Pic,

          title: "Blog 1",
          category1: "Development",
          category2: "AI",
          description: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Rerum, iure.",
          author: "Author Name",
          date: "Date posted",
        },
  // Add more items as needed
];
