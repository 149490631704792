// portfolioItems.js

import PortfolioImage from "../Assets/Portfolio/PortfolioImage.png";
import PortfolioImageAG from "../Assets/Portfolio/autogulf.jpg";
import PortfolioImageInayaa from "../Assets/Portfolio/inaya.jpg";
import PortfolioImageEF from "../Assets/Portfolio/efashionshop.jpg";

export const PortfolioData = [
  {
    pic: PortfolioImageAG,
    title: "AutoGulf",
    Tech1: "iOS and Android",
    Tech2: "Swift and Java",
    Description: `AutoGulf is a comprehensive mobile application designed for the Saudi Arabian market, available on both iOS and Android platforms. This app serves as a dynamic marketplace for buying and selling cars, as well as offering car services. Whether you are looking to purchase a new vehicle, sell your current car, or promote your automotive workshop, AutoGulf provides a seamless and user-friendly experience.

    With AutoGulf, users can create detailed car ads with photos, descriptions, and pricing information to reach a wide audience of potential buyers. For those offering car services, the app allows you to post service ads, showcasing your workshop's capabilities, specialties, and contact information. AutoGulf ensures that every transaction and service interaction is smooth and efficient, catering to the specific needs of the Saudi Arabian automotive market.
    
    Join the AutoGulf community today and experience the easiest way to buy, sell, and service cars in Saudi Arabia.`,
  },
  
  {
    pic: PortfolioImageInayaa,
    title: "Inayaa",
    Tech1: "iOS and Android",
    Tech2: "Swift and Java",
    Description: `Inayaa is a specialized mobile application available on both iOS and Android platforms, tailored for the healthcare sector in Saudi Arabia. This app is designed to streamline the management of hospital ambulances by maintaining comprehensive records of each ambulance and the associated driver information.

    With Inayaa, hospital administrators and fleet managers can easily track and update crucial data related to their ambulance fleet. The app allows users to log details such as vehicle maintenance schedules, service histories, and driver credentials, ensuring that all ambulances are in optimal condition and ready for emergencies. By centralizing this information, Inayaa enhances the efficiency of hospital operations, improves response times, and ensures compliance with safety regulations.
    
    Inayaa is the ideal solution for hospitals looking to maintain high standards of emergency medical services and ensure the safety and reliability of their ambulance fleets.`,
  },{
    pic: PortfolioImageEF,
    title: "eFashionshop",
    Tech1: "React JS",
    Tech2: "Node JS",
    Description: `Welcome to eFashionshop, your ultimate destination for stylish and affordable artificial jewelry. Exclusively available online, our e-commerce store caters to customers all over Pakistan, offering a vast collection of high-quality, trendy jewelry pieces perfect for any occasion.

    Shop for artificial jewelry at eFashionshop and enjoy a seamless and enjoyable experience. Our website features an easy-to-navigate interface, allowing users to browse through a diverse selection of necklaces, earrings, bracelets, rings, and more. Each product is meticulously detailed with clear images and descriptions, helping you make the best choice.
    
    eFashionshop ensures a convenient and secure shopping experience with multiple payment options, including Cash on Delivery (COD), credit card, bank transfer, and EasyPaisa. With prompt delivery services covering all regions of Pakistan, your favorite jewelry pieces are just a few clicks away from being part of your collection. Whether you're looking for something elegant for a special event or everyday fashion accessories, eFashionshop has you covered.
    
    Shop with confidence and elevate your style with eFashionshop's exquisite range of artificial jewelry.`,
  },
  
  // Add more items as needed
];
