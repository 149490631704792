import Person1 from '../Assets/Testimonials/mypic.png'

export const TestimonialsData = [
    // Add your testimonial data here
    {
      id: 1,
      pic:Person1,
      role: "Marketing Manager",
      companyName: "Auto Gulf",
      content: "They communicated smoothly and were always available. Customers can expect a cost-effective and effective team.",
      rating: '5.0',
    },
    {
      id: 2,
      pic:Person1,
      role: "Business Development Manager",
      companyName: "Enviro Care",
      content: "Working with this team to develop my mobile app was a fantastic experience. Their communication was seamless, and they were always readily available to address any concerns or questions.",
      rating: '5.0',
    },
    {
      id: 3,
      pic:Person1,

      role: "Owner",
      companyName: "eFashionshop",
      content: "I had an incredible experience working with these professionals. They expertly guided me throughout my journey, providing exceptional support and insight every step of the way.",
      rating: '5.0',
    },
    
    // Add more testimonial data as needed
  ];