import React from 'react'
import NavBar from '../../components/NavBar'
import BlogComponent from './BlogsComponents/BlogComponent'
import Footer from '../../components/Footer'

const BlogsIndex = () => {
  return (
    <>

<NavBar/>
<BlogComponent/>
<Footer/>

    </>
  )
}

export default BlogsIndex